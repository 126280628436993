<template>
    <v-row class="fill-height">
        <v-col>
            <v-sheet height="64">
                <v-toolbar flat>
                    <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"> HOY </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small> mdi-chevron-right </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right> mdi-menu-down </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>Dia</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>Semana</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>Mes</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
                <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="updateRange"
                ></v-calendar>
                <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
                    <v-card color="grey lighten-4" min-width="350px" flat>
                        <v-toolbar :color="selectedEvent.color" dark>
                            <v-toolbar-title>Detalles</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="eventoModificar(selectedEvent)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text v-if="selectedEvent.detalles">
                            <span v-html="selectedEvent.details"></span>
                            <v-list-item-title>DNI</v-list-item-title>
                            <v-list-item-subtitle> {{ selectedEvent.detalles.Dni }} </v-list-item-subtitle>
                            <br />
                            <v-list-item-title>Telefono</v-list-item-title>
                            <v-list-item-subtitle> {{ selectedEvent.detalles.Telefono }} </v-list-item-subtitle>
                            <br />
                            <v-list-item-title>Correo</v-list-item-title>
                            <v-list-item-subtitle> {{ selectedEvent.detalles.Correo }} </v-list-item-subtitle>
                            <br />
                            <v-list-item-title>FechaEmision</v-list-item-title>
                            <v-list-item-subtitle> {{ selectedEvent.detalles.FechaEmision }} </v-list-item-subtitle>
                            <br />
                            <v-list-item-title>FechaFinal</v-list-item-title>
                            <v-list-item-subtitle> {{ selectedEvent.detalles.FechaFinal }} </v-list-item-subtitle>
                            <br />
                            <v-list-item-title>Ticket</v-list-item-title>
                            <v-list-item-subtitle> {{ selectedEvent.detalles.Ticket }} </v-list-item-subtitle>
                        </v-card-text>
                        <v-card-text v-else-if="selectedEvent.Servicios">
                            <span v-html="selectedEvent.details"></span>

                            <v-list-item-title>Cliente</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ selectedEvent.nombreCliente }}
                            </v-list-item-subtitle>
                            <br />
                            <v-list-item-title>Servicio Mixto</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ selectedEvent.Servicios.ServicioMixto }}
                            </v-list-item-subtitle>
                            <br />
                            <v-list-item-title>Servicio Privado</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ selectedEvent.Servicios.ServicioPrivado }}
                            </v-list-item-subtitle>
                            <br />
                            <v-list-item-title>Servicio Masaje</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ selectedEvent.Servicios.ServicioMasajes }}
                            </v-list-item-subtitle>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn text color="secondary" @click="selectedOpen = false"> Cancel </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-sheet>
            <v-dialog v-model="modalReserva.state" scrollable max-width="1024" persistent>
                <ModalReserva v-if="modalReserva.state" />
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>
import ModalReserva from '@/components/modals/ModalReserva'
import { format, parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { mapState } from 'vuex'
export default {
    components: {
        ModalReserva
    },
    data: () => ({
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'MES',
            week: 'SEMANA',
            day: 'DIA',
            '4day': '4 Days'
        },
        selectedEvent: {},
        ventaPendiente: [],
        selectedElement: null,
        selectedOpen: false,
        events: [],
        colors: ['cyan']
    }),
    async created() {
        const { idBussines } = this.user
        //console.log('idBussines_Created', idBussines)

        //console.time('get_initial_values')
        //console.timeEnd('get_initial_values')
    },

    async mounted() {
        //console.log('mounted')
        await this.$refs.calendar.checkChange()
    },
    computed: {
        ...mapState('ventas', ['modalReserva', 'privadosToday', 'masajesToday', 'mixtosToday', 'productosToday', 'listaCalendarioToday', 'datos']),
        ...mapState('users', ['user']),
        ...mapState('ventas', ['listaVentaHisto'])
    },
    methods: {
        async eventoModificar(payload) {
            //console.log('patchVenta')
            await this.$store.dispatch('ventas/selectReserva', payload)

            this.$store.commit('ventas/SHOW_MODAL', {
                step: 2,
                state: true
            })
        },
        viewDay({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor(event) {
            return event.color
        },
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        showEvent({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        async updateRange() {
            var d = new Date()
            //console.log('user', this.user)
            const valorAnterior = formatInTimeZone(new Date(d.setMonth(d.getMonth() - 1)), 'America/Lima', 'yyyy-MM-dd hh:mm')
            const valorProximo = formatInTimeZone(new Date(d.setMonth(d.getMonth() + 2)), 'America/Lima', 'yyyy-MM-dd hh:mm')
            //console.log('valorAnterior', valorAnterior)
            //console.log('valorProximo', valorProximo)

            await this.$store.dispatch('ventas/getReservas', {
                firstDay: valorAnterior,
                nextDay: valorProximo
            })
            //console.log('listaVentaHisto', this.listaVentaHisto)
            this.ventaPendiente = this.listaVentaHisto.filter((element) => element.finalizado == 0)
            //console.log('ventaPendiente___updateRange', this.ventaPendiente)
            const events = []
            function isValidDate(day, month, year) {
                return `${year}-${month}-${day}`
            }
            const optionalRoles = JSON.parse(this.user.optionalRole)
            const rolColab = [2, 3, 4].includes(this.user.id_role)
                ? this.ventaPendiente
                : optionalRoles?.newVendor
                ? this.ventaPendiente.filter((x) => x.idColaborador == this.user.id)
                : this.ventaPendiente.filter((x) => x.idSede == this.user.idSede)
            if ([2, 4].includes(this.user.idBussines)) {
                for (const iterator of rolColab) {
                    if (iterator.idEstado != 9) {
                        let dateFormat
                        if (iterator.check_in.split('-')[1]) {
                            dateFormat = iterator.check_in
                        } else {
                            dateFormat = isValidDate(iterator.check_in.split('/')[0], iterator.check_in.split('/')[1], iterator.check_in.split('/')[2])
                        }
                        //console.log('dateFormat_V', dateFormat)
                        const productos = iterator.productos
                        const allDay = this.rnd(0, 3) === 0
                        if (productos.length > 0) {
                            events.push({
                                name: `${iterator.id ? iterator.id : '-'}-${productos ? productos[0].description : '-'}`,
                                start: dateFormat,
                                end: dateFormat,
                                id: iterator.id,
                                color: 'cyan',
                                timed: !allDay,
                                check_in: iterator.check_in,
                                detalles: {
                                    Dni: iterator.numeroDocumento ? iterator.numeroDocumento : '-',
                                    Telefono: iterator.telefono ? iterator.telefono : '-',
                                    Correo: iterator.correo ? iterator.correo : '-',
                                    FechaEmision: iterator.fechaCreacion ? iterator.fechaCreacion : '-',
                                    FechaFinal: iterator.check_in ? iterator.check_in : '-',
                                    Ticket: iterator.id ? iterator.id : '-'
                                }
                            })
                        }
                    }
                }
            } else {
                for (const iterator of rolColab) {
                    let dateFormat
                    if (iterator.check_in.split('-')[1]) {
                        dateFormat = iterator.check_in
                    } else {
                        dateFormat = isValidDate(iterator.check_in.split('/')[0], iterator.check_in.split('/')[1], iterator.check_in.split('/')[2])
                    }
                    //console.log('dateFormat_V', dateFormat)
                    let ServicioMixto = '-'
                    let ServicioPrivado = '-'
                    let ServicioMasajes = '-'
                    const valMixto = JSON.parse(iterator.mixtos)
                    const valPrivado = iterator.privados
                    const valMasaje = JSON.parse(iterator.masajes)
                    const allDay = this.rnd(0, 3) === 0
                    let nombreServicio = ''
                    let HoraEntrada = ''
                    let HoraSalida = ''
                    if (valMixto.length > 0 || valPrivado.length > 0 || valMasaje.length > 0) {
                        if (valMixto[0]) {
                            ServicioMixto = valMixto[0].description
                            nombreServicio = ServicioMixto
                            HoraEntrada = format(parseISO(valMixto[0].check_in_time), 'hh:mm a')
                            HoraSalida = format(parseISO(valMixto[0].check_out_time), 'hh:mm a')
                        }
                        if (valPrivado[0]) {
                            ServicioPrivado = valPrivado[0].description

                            nombreServicio = ServicioPrivado
                            HoraEntrada = format(parseISO(valPrivado[0].check_in_time), 'hh:mm a')
                            HoraSalida = format(parseISO(valPrivado[0].check_out_time), 'hh:mm a')
                        }
                        if (valMasaje[0]) {
                            ServicioMasajes = valMasaje[0].description
                            nombreServicio = ServicioMasajes
                            HoraEntrada = format(parseISO(valMasaje[0].check_in_time), 'hh:mm a')
                            HoraSalida = format(parseISO(valMasaje[0].check_out_time), 'hh:mm a')
                        }

                        events.push({
                            name: `${HoraEntrada} - ${HoraSalida} - ${nombreServicio}`,
                            start: dateFormat,
                            end: dateFormat,
                            nombreCliente: iterator.full_name,
                            color: 'cyan',
                            id: iterator.id,
                            timed: !allDay,
                            check_in: iterator.check_in,
                            Servicios: {
                                ServicioMixto,
                                ServicioPrivado,
                                ServicioMasajes
                            }
                        })
                    }
                }
            }
            this.events = events
            //console.log('events', this.events)
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        }
    }
}
</script>
